import { Colores, Order } from "../../Constantes"

const a = {
    helpUrl: 'https://www.arduino.cc/reference/en/libraries/wifi/',
    init: function () {
        this.setColour(Colores.wifi)
        this.appendDummyInput().appendField("Conectar Wifi")
        this.appendValueInput("SSID", 'String').appendField("SSID");
        this.appendValueInput("PASS", 'String').appendField("Contraseña");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setInputsInline(true);
    }
}
const b = (block, generator) => {
   

    generator.definitions_['wifi_include'] = '#include <WiFi.h>'
   
    const SSID = generator.valueToCode(block, 'SSID', Order.ATOMIC)
    const PASS = generator.valueToCode(block, 'PASS', Order.ATOMIC)
    generator.setups_['wifi_con'] = `
        WiFi.mode(WIFI_STA);
        WiFi.begin(${SSID}, ${PASS});
    `
    return ''
}

const a2 = {
    helpUrl: 'https://www.arduino.cc/reference/en/libraries/wifi/',
    init: function () {
        this.setColour(Colores.wifi)
        this.appendDummyInput().appendField("WIFI Conectado")
        this.setOutput(true, 'Boolean');
        this.setInputsInline(true);
    }
}

const b2 = (block, generator) => {
    const code = 'WiFi.status() == WL_CONNECTED'
    return [code, Order.ATOMIC];
}

const a3 = {
    helpUrl: 'https://www.arduino.cc/reference/en/libraries/wifi/',
    init: function () {
        this.setColour(Colores.wifi)
        this.appendDummyInput().appendField("WIFI IP")
        this.setOutput(true, 'String');
        this.setInputsInline(true);
    }
}

const b3 = (block, generator) => {
    const code = 'WiFi.localIP()'
    return [code, Order.ATOMIC];
}

const mutar_wifi = {
    on_conectado: 0,
    on_desconectado: 0,
    mutationToDom: function () {
        var container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('on_conectado', this.on_conectado);
        container.setAttribute('on_desconectado', this.on_desconectado);
        return container;
    },
    domToMutation: function (xmlElement) {
        this.on_conectado = parseInt(xmlElement.getAttribute('on_conectado'), 0)
        this.on_desconectado = parseInt(xmlElement.getAttribute('on_desconectado'), 0)
        this.updateShape_()
    },
    decompose: function (workspace) {
        console.log("DESCOMPONER")
        var containerBlock = workspace.newBlock('wifi_eventos_container');
        containerBlock.initSvg();
        var connection = containerBlock.getInput('STACK').connection;
        let itemBlock = null

        if (this.on_conectado == 1) {
            itemBlock = workspace.newBlock('wifi_on_conectado');
            itemBlock.initSvg();
            connection.connect(itemBlock.previousConnection);
            connection = itemBlock.nextConnection;
        }
        if (this.on_desconectado == 1) {
            itemBlock = workspace.newBlock('wifi_on_desconectado');
            itemBlock.initSvg();
            connection.connect(itemBlock.previousConnection);
            connection = itemBlock.nextConnection;
        }
        return containerBlock;
    },
    compose: function (containerBlock) {
        console.log("COMPONER")
        var itemBlock = containerBlock.getInputTargetBlock('STACK');

        var connections = [];
        this.on_conectado = 0
        this.on_desconectado = 0

        while (itemBlock && !itemBlock.isInsertionMarker()) {
            if (itemBlock.type == "wifi_on_conectado") {
                this.on_conectado = 1
            }
            if (itemBlock.type == "wifi_on_desconectado") {
                this.on_desconectado = 1
            }
            connections.push(itemBlock.valueConnection_);
            itemBlock = itemBlock.nextConnection &&
                itemBlock.nextConnection.targetBlock();
        }
       

        this.updateShape_();
    },
    saveConnections: function (containerBlock) {
        var itemBlock = containerBlock.getInputTargetBlock('STACK');
        while (itemBlock) {
            let input = this.getInput('on_conectado');
            if (input) {
                itemBlock.valueConnection_ = input.connection.targetConnection;
            }
            input = this.getInput('on_desconectado');
            if (input) {
                itemBlock.valueConnection_ = input.connection.targetConnection;
            }
            itemBlock = itemBlock.nextConnection &&
                itemBlock.nextConnection.targetBlock();
        }
    },
    updateShape_: function () {

        if (this.getInput('on_conectado') && this.on_conectado == 0) {
            this.removeInput('on_conectado');
        }

        if (this.getInput('on_desconectado') && this.on_desconectado == 0) {
            this.removeInput('on_desconectado');
        }

        if (this.on_conectado == 1) {
            if (!this.getInput('on_conectado')) {
                console.log("Crear on conectado")
                this.appendStatementInput('on_conectado').setAlign(Blockly.ALIGN_RIGHT).appendField('Conectado');
            }
        }
        if (this.on_desconectado == 1) {
            if (!this.getInput('on_desconectado')) {
                this.appendStatementInput('on_desconectado').setAlign(Blockly.ALIGN_RIGHT).appendField('Desconectado');
            }
        }

    }
}

Blockly.Extensions.registerMutator("mutar_wifi", mutar_wifi, undefined, ['wifi_on_conectado', 'wifi_on_desconectado']);

const a4 = {
    init: function () {
        this.appendDummyInput()
            .appendField("WIFI eventos");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(Colores.wifi);
        this.setTooltip('');
        this.setHelpUrl('');
        this.jsonInit({
            "mutator": "mutar_wifi"
        });
        this.itemCount_ = 1;


    }

}

const b4 = (block, generator) => {
    const conectado = generator.statementToCode(block, 'on_conectado');
    const desconectado = generator.statementToCode(block, 'on_desconectado');

    

    if (conectado) {
        generator.definitions_['wifi_on_conectado'] = `
        void wifi_on_conectado(WiFiEvent_t wifi_event, WiFiEventInfo_t wifi_info){
            ${conectado}
        }
    `
        generator.setups_['wifi_conectado'] = 'WiFi.onEvent(wifi_on_conectado, ARDUINO_EVENT_WIFI_STA_CONNECTED);'
    }
    if (desconectado) {
        generator.definitions_['wifi_on_desconectado'] = `
        void wifi_on_desconectado(WiFiEvent_t wifi_event, WiFiEventInfo_t wifi_info){
            ${desconectado}
        }
    `
        generator.setups_['wifi_desconectado'] = 'WiFi.onEvent(wifi_on_desconectado, ARDUINO_EVENT_WIFI_STA_DISCONNECTED);'
    }



    return ''
}

function registrar(_a, _b) {


    _a['wifi_eventos_container'] = {
        init: function () {
            this.setColour(Colores.wifi);
            this.appendDummyInput()
                .appendField("Eventos");
            this.appendStatementInput("STACK");
            this.setTooltip('');
            this.contextMenu = false;
        }
    };

    _a['wifi_on_conectado'] = {
        init: function () {
            this.appendDummyInput().appendField('Conectado')
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Colores.wifi2);
            this.setTooltip('');
            this.contextMenu = false;
        }
    };

    _a['wifi_on_desconectado'] = {
        init: function () {
            this.appendDummyInput().appendField('Desconectado')
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Colores.wifi2);
            this.setTooltip('');
            this.contextMenu = false;
        }
    };

    _a['wifi_con'] = a
    _b['wifi_con'] = b

    _a['wifi_conectado'] = a2
    _b['wifi_conectado'] = b2

    _a['wifi_ip'] = a3
    _b['wifi_ip'] = b3

    _a['wifi_eventos'] = a4
    _b['wifi_eventos'] = b4
}


export default registrar