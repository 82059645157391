import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
      this.setColour(Colores.operador);
      this.setOutput(true, 'Boolean');
      this.appendValueInput('A')
        .setCheck('Boolean');
  
      this.appendDummyInput().appendField('o')
  
      this.appendValueInput('B')
        .setCheck('Boolean')
  
  
      this.setInputsInline(true);
    }
  }

const b = function (block, generator) {
    const order = Order.LOGICAL_OR
    const operator = 'or'
    let argument0 = generator.valueToCode(block, 'A', order);
    let argument1 = generator.valueToCode(block, 'B', order);
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
}


export default (_a, _b)=>{
  _a['logica_o'] = a
  _b['logica_o'] = b
}