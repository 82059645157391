import { Colores, Order } from "../../Constantes";

const a = {
    helpUrl: 'http://arduino.cc/en/Reference/delay',
    init: function () {
      this.setColour(Colores.control);
  
      this.appendDummyInput()
        .appendField("Esperar")
  
      this.appendValueInput("DELAY_TIME", "Number").setCheck("Number")
  
      this.appendDummyInput().appendField("ms.");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip('Delay specific time');
    }
  }

const b = function(block,generator){
    var delay_time = generator.valueToCode(block, 'DELAY_TIME', Order.ATOMIC) || '1000'
  
    var code = 'delay('+ delay_time +');\n';
    return code;
  }

export default (_a, _b) => {
    _a['base_delay'] = a
    _b['base_delay'] = b
}