const _temp0 = require("./Bloques/AltoBajo/index.js");

const _temp1 = require("./Bloques/Analogo/index.js");

const _temp2 = require("./Bloques/Base/index.js");

const _temp3 = require("./Bloques/Ble/index.js");

const _temp4 = require("./Bloques/Comparar/index.js");

const _temp5 = require("./Bloques/Digital/index.js");

const _temp6 = require("./Bloques/Delay/index.js");

const _temp7 = require("./Bloques/Dividir/index.js");

const _temp8 = require("./Bloques/For/index.js");

const _temp9 = require("./Bloques/Funciones/index.js");

const _temp10 = require("./Bloques/IgualQue/index.js");

const _temp11 = require("./Bloques/LogicOperation/index.js");

const _temp12 = require("./Bloques/Loop/index.js");

const _temp13 = require("./Bloques/MatematicaSimple/index.js");

const _temp14 = require("./Bloques/MayorQue/index.js");

const _temp15 = require("./Bloques/MenorQue/index.js");

const _temp16 = require("./Bloques/Mientras/index.js");

const _temp17 = require("./Bloques/Multiplicar/index.js");

const _temp18 = require("./Bloques/Negar/index.js");

const _temp19 = require("./Bloques/NumeroAleatorio/index.js");

const _temp20 = require("./Bloques/O/index.js");

const _temp21 = require("./Bloques/Ota/index.js");

const _temp22 = require("./Bloques/Restar/index.js");

const _temp23 = require("./Bloques/Serial/index.js");

const _temp24 = require("./Bloques/Setup/index.js");

const _temp25 = require("./Bloques/Si/index.js");

const _temp26 = require("./Bloques/Sumar/index.js");

const _temp27 = require("./Bloques/SwitchCase/index.js");

const _temp28 = require("./Bloques/Variable/index.js");

const _temp29 = require("./Bloques/Y/index.js");

const _temp30 = require("./Bloques/Wifi/index.js");

module.exports = {
  "AltoBajo":   {
    "index": _temp0
  },
  "Analogo":   {
    "index": _temp1
  },
  "Base":   {
    "index": _temp2
  },
  "Ble":   {
    "index": _temp3
  },
  "Comparar":   {
    "index": _temp4
  },
  "Digital":   {
    "index": _temp5
  },
  "Delay":   {
    "index": _temp6
  },
  "Dividir":   {
    "index": _temp7
  },
  "For":   {
    "index": _temp8
  },
  "Funciones":   {
    "index": _temp9
  },
  "IgualQue":   {
    "index": _temp10
  },
  "LogicOperation":   {
    "index": _temp11
  },
  "Loop":   {
    "index": _temp12
  },
  "MatematicaSimple":   {
    "index": _temp13
  },
  "MayorQue":   {
    "index": _temp14
  },
  "MenorQue":   {
    "index": _temp15
  },
  "Mientras":   {
    "index": _temp16
  },
  "Multiplicar":   {
    "index": _temp17
  },
  "Negar":   {
    "index": _temp18
  },
  "NumeroAleatorio":   {
    "index": _temp19
  },
  "O":   {
    "index": _temp20
  },
  "Ota":   {
    "index": _temp21
  },
  "Restar":   {
    "index": _temp22
  },
  "Serial":   {
    "index": _temp23
  },
  "Setup":   {
    "index": _temp24
  },
  "Si":   {
    "index": _temp25
  },
  "Sumar":   {
    "index": _temp26
  },
  "SwitchCase":   {
    "index": _temp27
  },
  "Variable":   {
    "index": _temp28
  },
  "Y":   {
    "index": _temp29
  },
  "Wifi":   {
    "index": _temp30
  }
}