import { Colores, Order } from "../../Constantes";


const numero = {
    init: function () {
        this.setColour(Colores.matematica);
        this.appendDummyInput()
            .appendField(new Blockly.FieldTextInput('0',
                Blockly.FieldTextInput.numberValidator), 'NUM');
        this.setOutput(true, 'Number');
        this.setTooltip(Blockly.Msg.MATH_NUMBER_TOOLTIP);
    }
}

const texto = {
    init: function () {
        this.setColour(Colores.matematica);
        this.appendDummyInput()
            .appendField(new Blockly.FieldTextInput('',
                Blockly.FieldTextInput.numberValidator), 'TEXT');
        this.setOutput(true, 'String');
    }
}

const _numero = function (block, generator) {
    var code = window.parseFloat(this.getFieldValue('NUM'));
    var order = code < 0 ? Order.UNARY_PREFIX : Order.ATOMIC;
    return [code, order];
}
const _texto = function (block, generator) {
    const code = generator.quote_(block.getFieldValue('TEXT'));
    return [code, Order.ATOMIC];
};


export default (_a, _b) => {
    _a['math_number'] = numero
    _a['text'] = texto

    _b['math_number'] = _numero
    _b['text'] = _texto
}