const a = {
    helpUrl: 'https://www.arduino.cc/reference/en/language/structure/sketch/setup/',
    init: function () {
        this.setDeletable(false)
        this.setEditable(false)
        this.setMovable(false)
        this.jsonInit({
            'message0': 'Inicializar %1',
            'args0': [
                {
                    'type': 'input_statement',
                    'name': 'setup'
                }
            ],
            'colour': '#FFBF00',
        })
    }
}

const b = function (block, generator) {

    let code = generator.statementToCode(block, 'setup');
    code = code.replace(/\n/g, "\n");
    code = code.replace(/\n\s+$/, "\n");

    return '#iniciosetup' + code + '#finsetup'
}

function registrar(_a, _b) {
    _a['setup'] = a
    _b['setup'] = b
}


export default registrar