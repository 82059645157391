import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
        this.jsonInit({
            'type': 'logic_operation',
            'message0': '%1 %2 %3',
            'args0': [
                {
                    'type': 'input_value',
                    'name': 'A',
                    'check': 'Boolean',
                },
                {
                    'type': 'field_dropdown',
                    'name': 'OP',
                    'options': [
                        ['%{BKY_LOGIC_OPERATION_AND}', 'AND'],
                        ['%{BKY_LOGIC_OPERATION_OR}', 'OR'],
                    ],
                },
                {
                    'type': 'input_value',
                    'name': 'B',
                    'check': 'Boolean',
                },
            ],
            'inputsInline': true,
            'output': 'Boolean',
            colour: Colores.control,
            'helpUrl': '%{BKY_LOGIC_OPERATION_HELPURL}',
            'extensions': ['logic_op_tooltip'],
        })
    }
}

const b = function (block, generator) {
    // Operations 'and', 'or'.
    const operator = (block.getFieldValue('OP') === 'AND') ? '&&' : '||';
    const order = (operator === '&&') ? Order.LOGICAL_AND :
        Order.LOGICAL_OR;
    let argument0 = generator.valueToCode(block, 'A', order);
    let argument1 = generator.valueToCode(block, 'B', order);
    if (!argument0 && !argument1) {
        // If there are no arguments, then the return value is false.
        argument0 = 'false';
        argument1 = 'false';
    } else {
        // Single missing arguments have no effect on the return value.
        const defaultArgument = (operator === '&&') ? 'true' : 'false';
        if (!argument0) {
            argument0 = defaultArgument;
        }
        if (!argument1) {
            argument1 = defaultArgument;
        }
    }
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
};

function registrar(_a, _b) {
    _a['logic_operation'] = a
    _b['logic_operation'] = b
}

export default registrar