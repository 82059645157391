import { Order } from "../../Constantes";

const casos = {
    init: function () {
        this.setColour('#e5ab00'); // Color del bloque (puedes cambiarlo)
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.appendValueInput('VARIABLE')
            .setCheck(null) // Puedes especificar el tipo de conexión
            .appendField('En caso de que');
        this.appendStatementInput('CASES')
            .setCheck('case')
            .appendField('');

        this.appendStatementInput('DEFAULT')
            .appendField('Si nada se cumple').setCheck(null);
        this.setTooltip('Switch case block');
        this.setHelpUrl('https://www.arduino.cc/reference/es/language/structure/control-structure/switchcase/');
    }
};

const caso = {
    init: function () {
        this.setColour('#FFBF00'); // Color del bloque (puedes cambiarlo)
        this.appendValueInput('VALUE')
            .setCheck(null) // Puedes especificar el tipo de conexión
            .appendField('Sea');
        this.appendStatementInput('DO')
            .appendField('Hacer');
        this.setPreviousStatement(true, 'case');
        this.setNextStatement(true, 'case');
        this.setTooltip('Case block');
    }
};

const _casos = function (block, generator) {
    var variable = generator.valueToCode(block, 'VARIABLE', Order.ATOMIC);
    var cases = generator.statementToCode(block, 'CASES');
    var defaultCode = generator.statementToCode(block, 'DEFAULT');

    const code = 'switch (' + variable + ') {\n' + cases + 'default:\n' + defaultCode + '\nbreak;\n}\n';
    return code;
};

const _caso = function (block, generator) {
    var value = generator.valueToCode(block, 'VALUE', Order.ATOMIC);
    var statements_do = generator.statementToCode(block, 'DO');
    var code = 'case ' + value + ':\n' + statements_do;
    return code;
};




export default (_a, _b) => {
    _a['switch_case'] = casos
    _a['case'] = caso
    _b['switch_case'] = _casos
    _b['case'] = _caso
}