import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
      this.jsonInit({
        'type': 'controls_for',
        'message0': '%{BKY_CONTROLS_FOR_TITLE}',
        'args0': [
          {
            'type': 'field_variable',
            'name': 'VAR',
            'variable': null,
          },
          {
            'type': 'input_value',
            'name': 'FROM',
            'check': 'Number',
            'align': 'RIGHT',
          },
          {
            'type': 'input_value',
            'name': 'TO',
            'check': 'Number',
            'align': 'RIGHT',
          },
          {
            'type': 'input_value',
            'name': 'BY',
            'check': 'Number',
            'align': 'RIGHT',
          },
        ],
        'message1': '%{BKY_CONTROLS_REPEAT_INPUT_DO} %1',
        'args1': [
          {
            'type': 'input_statement',
            'name': 'DO',
          },
        ],
        'inputsInline': true,
        'previousStatement': null,
        'nextStatement': null,
        'colour': Colores.control,
        'helpUrl': '%{BKY_CONTROLS_FOR_HELPURL}',
        'extensions': ['contextMenu_newGetVariableBlock', 'controls_for_tooltip'],
      })
    }
  }

const b = function(block, generator) {
    // For loop.
    const variable0 =
        generator.getVariableName(
          block.getFieldValue('VAR'));
    const argument0 =
        generator.valueToCode(block, 'FROM', Order.ASSIGNMENT) || '0';
    const argument1 =
        generator.valueToCode(block, 'TO', Order.ASSIGNMENT) || '0';
    const increment =
        generator.valueToCode(block, 'BY', Order.ASSIGNMENT) || '1';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block);
    let code;
    if (Blockly.utils.string.isNumber(argument0) && Blockly.utils.string.isNumber(argument1) &&
    Blockly.utils.string.isNumber(increment)) {
      // All arguments are simple numbers.
      const up = parseFloat(argument0) <= parseFloat(argument1);
      code = 'for (' + variable0 + ' = ' + argument0 + '; ' + variable0 +
          (up ? ' <= ' : ' >= ') + argument1 + '; ' + variable0;
      const step = Math.abs(Number(increment));
      if (step === 1) {
        code += up ? '++' : '--';
      } else {
        code += (up ? ' += ' : ' -= ') + step;
      }
      code += ') {\n' + branch + '}\n';
    } else {
      code = '';
      // Cache non-trivial values to variables to prevent repeated look-ups.
      let startVar = argument0;
      if (!argument0.match(/^\w+$/) && !Blockly.utils.string.isNumber(argument0)) {
        startVar = generator.nameDB_.getDistinctName(
            variable0 + '_start', NameType.VARIABLE);
        code += 'int ' + startVar + ' = ' + argument0 + ';\n';
      }
      let endVar = argument1;
      if (!argument1.match(/^\w+$/) && !Blockly.utils.string.isNumber(argument1)) {
        endVar = generator.nameDB_.getDistinctName(
            variable0 + '_end', NameType.VARIABLE);
        code += 'int ' + endVar + ' = ' + argument1 + ';\n';
      }
      // Determine loop direction at start, in case one of the bounds
      // changes during loop execution.
      const incVar = generator.nameDB_.getDistinctName(
          variable0 + '_inc', NameType.VARIABLE);
      code += 'int ' + incVar + ' = ';
      if (Blockly.utils.string.isNumber(increment)) {
        code += Math.abs(increment) + ';\n';
      } else {
        code += 'Math.abs(' + increment + ');\n';
      }
      code += 'if (' + startVar + ' > ' + endVar + ') {\n';
      code += generator.INDENT + incVar + ' = -' + incVar + ';\n';
      code += '}\n';
      code += 'for (' + variable0 + ' = ' + startVar + '; ' + incVar +
          ' >= 0 ? ' + variable0 + ' <= ' + endVar + ' : ' + variable0 +
          ' >= ' + endVar + '; ' + variable0 + ' += ' + incVar + ') {\n' +
          branch + '}\n';
    }
    return code;
}

export default (_a, _b) => {
    _a['controls_for'] = a
    _b['controls_for'] = b
}