import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
        this.jsonInit({
          "message0": Blockly.Msg.MATH_RANDOM_INT_TITLE,
          "args0": [
            {
              "type": "input_value",
              "name": "FROM",
              "check": "Number"
            },
            {
              "type": "input_value",
              "name": "TO",
              "check": "Number"
            }
          ],
          "inputsInline": true,
          "output": "Number",
          "colour": Colores.operador,
          "tooltip": Blockly.Msg.MATH_RANDOM_INT_TOOLTIP,
          "helpUrl": Blockly.Msg.MATH_RANDOM_INT_HELPURL
        });
      }
}

const b = function(block, generator) {
    // Random integer between [X] and [Y].
    const argument0 = generator.valueToCode(block, 'FROM',
        Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'TO',
        Order.NONE) || '0';
  
    const code = 'random(' + argument0 + ', ' + argument1 + ')'
    return [code, Order.NONE];
};

export default (_a, _b) => {
    _a['math_random_int'] = a
    _b['math_random_int'] = b
}