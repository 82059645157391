import { Colores, Order } from "../../Constantes"

const OPCIONES_DEFECTO = {
    nothing_selected: 'Seleccione'
}
const BLOQUE_BLE_SERVICE = 'ble_service'
const BLOQUE_BLE_CARACTERISTICA = 'ble_characteristic'
const BLOQUE_BLE_ESCRIBIR_CARACTERISTICA = 'ble_characteristic_write'


function getServicio(caracteristica) {
    let servicio = null, servicio_anterior = null
    try {
        let i = 0
        servicio = caracteristica.getParent()

        if (!servicio) {
            return null
        }
        while (servicio.type != BLOQUE_BLE_SERVICE) {
            servicio_anterior = servicio
            servicio = servicio?.getParent()

            i++
            if (i > 1000) {
                throw 'Las caracteristicas deben estar dentro de un servicio'
            }
        }
        return servicio
    } catch (err) {
        console.log(err)
        return null
    }
}

function getCaracteristicas(servicio_id) {
    const caracteristicas = { ...OPCIONES_DEFECTO }
    if (servicio_id != 'nothing_selected') {
        const servicio = Blockly.getMainWorkspace()?.getBlockById(servicio_id)
        let CHARACTERISTIS = []
        let [ca] = servicio.childBlocks_?.filter(t => t.type == 'ble_characteristic')
        while (ca && ca.type == BLOQUE_BLE_CARACTERISTICA) {
            CHARACTERISTIS.push(ca)
            ca = ca.getNextBlock()
        }

        const _caracteristicas = CHARACTERISTIS
        if (_caracteristicas && _caracteristicas.length) {
            _caracteristicas.forEach((c) => {
                const nombre = c.getFieldValue('NOMBRE')
                caracteristicas[c.id] = nombre
            })
        }
    }
    return caracteristicas
}

function onServicio(bloques, bloque) {

    if (bloque?.type != BLOQUE_BLE_SERVICE) {
        return
    }
    if (bloque.getFieldValue("UUID") == "") {
        bloque.setFieldValue(window.crypto.randomUUID(), 'UUID')
    }
    const nombre = bloque.getFieldValue('NOMBRE')
    bloques.forEach(function (otherBlock) {
        if (otherBlock.type === BLOQUE_BLE_CARACTERISTICA && otherBlock?.servicios) {
            otherBlock.servicios[bloque.id] = nombre;
        }
    });
    bloque.render()
}

function onCaracteristica(bloque) {
    if (bloque?.type != BLOQUE_BLE_CARACTERISTICA) {
        return
    }
    if (bloque.getFieldValue("UUID") == "") {
        bloque.setFieldValue(window.crypto.randomUUID(), 'UUID')
    }
    bloque.render()
}

function onEscribirCaracteristica(bloques, bloque) {
    bloques.forEach(function (otherBlock) {
        if (otherBlock.type === BLOQUE_BLE_SERVICE && bloque?.servicios) {
            const otherBlockName = otherBlock.getFieldValue('NOMBRE')
            bloque.servicios[otherBlock.id] = otherBlockName;
        }
    });
}

function onChangeServicio(evento, bloques, bloque) {

    if (bloque.type != BLOQUE_BLE_SERVICE) {
        return
    }
    if (evento.name != 'NOMBRE') {
        return
    }
    bloques.forEach(function (_bloque) {
        if (_bloque.type === BLOQUE_BLE_ESCRIBIR_CARACTERISTICA && _bloque?.servicios) {
            _bloque.servicios[bloque.id] = evento.newValue;
            var currentSelectedOption = _bloque.getField('SERVICIO').getValue()
            if (currentSelectedOption === bloque.id) {
                _bloque.getField('SERVICIO').getOptions(false);
                _bloque.getField('SERVICIO').setValue("nothing_selected");
                _bloque.getField('SERVICIO').setValue(currentSelectedOption);

            }
        }
    })
}


export function onCreateBle(espacio_de_trabajo, evento) {
    if (evento.type != Blockly.Events.BLOCK_CREATE) {
        return
    }
    var eventBlockIds = evento.ids;
    if (!eventBlockIds) {
        eventBlockIds = [evento.blockId];
    }
    if (!eventBlockIds.length) {
        return
    }
    var allBlocks = espacio_de_trabajo.getAllBlocks();
    eventBlockIds.forEach(function (eventBlockId) {
        const newBlock = espacio_de_trabajo.getBlockById(eventBlockId);
        onServicio(allBlocks, newBlock)
        onCaracteristica(newBlock)
        onEscribirCaracteristica(allBlocks, newBlock)
    })
}

export function onChangeBle(espacio_de_trabajo, evento) {
    if (evento.type != Blockly.Events.BLOCK_CHANGE) {
        return
    }
    var eventBlockIds = evento.ids;
    if (!eventBlockIds) {
        eventBlockIds = [evento.blockId];
    }
    if (!eventBlockIds.length) {
        return
    }
    const bloques = espacio_de_trabajo.getAllBlocks();
    eventBlockIds.forEach(function (eventBlockId) {
        const bloque = espacio_de_trabajo.getBlockById(eventBlockId);
        onChangeServicio(evento, bloques, bloque)
    })
}

export function onBorrarBle(espacio_de_trabajo, evento) {
    if (evento.type != Blockly.Events.BLOCK_DELETE) {
        return
    }
    var eventBlockIds = evento.ids;
    if (!eventBlockIds) {
        eventBlockIds = [evento.blockId];
    }
    if (!eventBlockIds.length) {
        return
    }
    const bloques = espacio_de_trabajo.getAllBlocks();

    eventBlockIds.forEach(function (eventBlockId) {

        bloques.forEach(function (otherBlock) {
            if (otherBlock.type == BLOQUE_BLE_ESCRIBIR_CARACTERISTICA) {

                delete otherBlock.servicios[eventBlockId];
                var otherBlockSelectedOption = otherBlock.getField('SERVICIO').getValue()
                if (otherBlockSelectedOption === eventBlockId) {
                    otherBlock.getField('SERVICIO').setValue("nothing_selected");
                }


                delete otherBlock.caracteristicas[eventBlockId];
                var otherBlockSelectedOption = otherBlock.getField('CARACTERISTICA').getValue()
                if (otherBlockSelectedOption === eventBlockId) {
                    otherBlock.getField('CARACTERISTICA').setValue("nothing_selected");
                }


            }
        })
    })
}

Blockly.Extensions.register('dropdown_extension', function () {
    if (!this.servicios) {
        this.servicios = { ...OPCIONES_DEFECTO }
    }
    if (!this.caracteristicas) {
        this.caracteristicas = { ...OPCIONES_DEFECTO }
    }

    var block = this;

    this.getInput('INPUT').appendField(new Blockly.FieldDropdown(function () {
        var optionsAsArray = [];
        Object.keys(block.servicios).forEach(function (key) {
            var value = block.servicios[key];
            if (value.length) {
                optionsAsArray.push([value, key]);
            }

        });

        return optionsAsArray;
    }, function (option) {


        const c_seleccionada = block.getField('CARACTERISTICA').getValue()
        block.getField('CARACTERISTICA').getOptions(false);
        block.getField('CARACTERISTICA').setValue("nothing_selected");
        const nuevas_caracteristicas = getCaracteristicas(option)
        block.caracteristicas = nuevas_caracteristicas
        if (nuevas_caracteristicas[c_seleccionada]) {
            block.getField('CARACTERISTICA').setValue(c_seleccionada);
        }
        try {
            block.render()
        } catch (err) {

        }



    }), "SERVICIO");

    this.getInput('INPUT').appendField(new Blockly.FieldDropdown(function () {
        var optionsAsArray = [];

        Object.keys(block?.caracteristicas).forEach(function (key) {
            var value = block.caracteristicas[key];
            optionsAsArray.push([value, key]);
        });

        return optionsAsArray;
    }), "CARACTERISTICA");

    //  console.log("SERVICE ",this.getFieldValue("SERVICE"))




});

var dropdownMutator = {
    mutationToDom: function () {
        // Save the dropdown options, to make sure they are still available when the workspace is loaded.
        var mutationXml = document.createElement('mutation');

        var dropdownOptionsAsJson = JSON.stringify(this.servicios);
        const caracteristicas = JSON.stringify(this.caracteristicas);

        mutationXml.setAttribute('dropdown_options', dropdownOptionsAsJson);
        mutationXml.setAttribute('caracteristicas', caracteristicas);

        return mutationXml;
    },
    domToMutation: function (xmlElement) {
        // Load the saved dropdown field options into this block
        var selectedOption = xmlElement.getAttribute('dropdown_selected');
        var dropdownOptionsAsJson = xmlElement.getAttribute('dropdown_options');
        var caracteristicas = xmlElement.getAttribute('caracteristicas');

        this.servicios = JSON.parse(dropdownOptionsAsJson);
        this.caracteristicas = JSON.parse(caracteristicas)
    }
};

Blockly.Extensions.registerMutator("dropdown_mutator", dropdownMutator);

export default (a, b) => {
    a['ble_init'] = {
        helpUrl: 'https://www.arduino.cc/reference/en/libraries/esp32-ble-arduino/',
        init: function () {
            this.setColour(Colores.ble)
            this.appendDummyInput().appendField("Inicializar BLE")

            this.appendValueInput("NOMBRE", 'String')
                .appendField("Nombre");

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);

            this.appendStatementInput('onConectado').appendField('Conectado')
            this.appendStatementInput('onDesconectado').appendField('Desconectado')
            this.appendStatementInput('SERVICES').setCheck('service_ble')
        }
    }

    a['ble_service'] = {
        init: function () {
            this.setColour('#3061B7')

            this.appendDummyInput()
                .appendField('Servicio').appendField(new Blockly.FieldTextInput(''), 'NOMBRE')
                .appendField('UUID').appendField(new Blockly.FieldTextInput(''), 'UUID');

            this.appendStatementInput('CHARACTERISTIS').appendField('Características').setCheck('ble_characteristic');
            this.setPreviousStatement(true, ['service_ble']);
            this.setNextStatement(true, ['service_ble']);
            this.setTooltip('Servicio ble');
        },
        onChange: (e) => {

        }
    };

    a['ble_characteristic'] = {
        init: function () {
            this.setColour('#517ECC')

            this.appendDummyInput()
                .appendField('Caracteristica')
                .appendField(new Blockly.FieldTextInput(''), 'NOMBRE')
                .appendField('UUID').appendField(new Blockly.FieldTextInput(''), 'UUID');

            //this.appendDummyInput().appendField('UUID').appendField(new Blockly.FieldTextInput(''), 'UUID');

            this.appendStatementInput('onWrite').appendField('Hacer');


            this.setPreviousStatement(true, 'ble_characteristic');
            this.setNextStatement(true, 'ble_characteristic');
            this.setTooltip('Characteristic ble');
        }
    };

    a['ble_characteristic_value'] = {
        init: function () {
            this.setColour(Colores.ble)
            this.appendDummyInput().appendField("Valor númerico de esta caracteristica")
            this.setOutput(true, 'String');
        }
    }

    a['ble_characteristic_value_str'] = {
        init: function () {
            this.setColour(Colores.ble)
            this.appendDummyInput().appendField("Valor string de esta caracteristica")
            this.setOutput(true, 'String');
        }
    }

    a['ble_characteristic_write'] = {
        init: function () {
            this.jsonInit({
                "type": "ble_characteristic_write",
                "message0": "ble write %1 %2",
                "args0": [
                    {
                        "type": "input_dummy",
                        "name": "INPUT"
                    },
                    {
                        "type": "input_value",
                        "name": "VALUE"
                    }
                ],
                "inputsInline": true,
                "previousStatement": null,
                "nextStatement": null,
                "colour": Colores.ble,
                "helpUrl": null,
                "extensions": ["dropdown_extension"],
                "mutator": ["dropdown_mutator"]
            });

        }
    }



    b['ble_init'] = function (block, generator) {

        generator.definitions_['ble_device'] = '#include <BLEDevice.h>'
        generator.definitions_['ble_utils'] = '#include <BLEUtils.h>'
        generator.definitions_['ble_2902'] = '#include <BLE2902.h>'
        generator.definitions_['ble_server_var'] = `
        BLEServer* pServer = NULL;
        bool deviceConnected = false;
        bool oldDeviceConnected = false;
        `

        const conectado = generator.statementToCode(block, 'onConectado');
        var desconectado = generator.statementToCode(block, 'onDesconectado');

        generator.definitions_['ble_server_callback'] = `
        class BleServerCallbacks: public BLEServerCallbacks {
            void onConnect(BLEServer* pServer) {
                deviceConnected = true;
                ${conectado}
            };
            void onDisconnect(BLEServer* pServer) {
                deviceConnected = false;
                ${desconectado}
                delay(500);
                pServer->startAdvertising(); 
            }
        };`



        var services = generator.statementToCode(block, 'SERVICES');
        const NOMBRE = generator.valueToCode(block, 'NOMBRE', Order.ATOMIC) || 'BOTFLOW';


        generator.setups_['_setup_ble'] = `
        // Create the BLE Device
        BLEDevice::init(${NOMBRE});
        pServer = BLEDevice::createServer();
        pServer->setCallbacks(new BleServerCallbacks());
        BLEAdvertising *pAdvertising = BLEDevice::getAdvertising();
      
        ${services}
      
        // Start advertising
        pAdvertising->setScanResponse(false);
        pAdvertising->setMinPreferred(0x0);  // set value to 0x00 to not advertise this parameter
        BLEDevice::startAdvertising();
        `
        return '';
    }

    b['ble_service'] = function (block, generator) {
        const UUID = block.getFieldValue("UUID")
        //const NOMBRE = generator.valueToCode(block, 'NOMBRE', Order.ATOMIC) || '';
        const NOMBRE = block.getFieldValue('NOMBRE')
        const name = Blockly.utils.idGenerator.getNextUniqueId().replace("blockly-", "bs")
        block.name = name
        const characteristics = generator.statementToCode(block, 'CHARACTERISTIS');

        const code = `
        //${NOMBRE}
        BLEService *${name} = pServer->createService("${UUID}");
        pAdvertising->addServiceUUID("${UUID}");
        ${characteristics}
        ${name}->start();
        `
        return code
    }

    b['ble_characteristic'] = function (block, generator) {

        const servicio = getServicio(block)

        if (!servicio) {
            return ''
        }

        const service = servicio.name
        const NOMBRE = block.getFieldValue('NOMBRE') || '';
        const UUID = block.getFieldValue("UUID")
        const name = Blockly.utils.idGenerator.getNextUniqueId().replace("blockly-", "ch")
        const onWrite = generator.statementToCode(block, 'onWrite');
        block.nombre = name
        generator.definitions_['ble_' + name] = `
        //${NOMBRE}
        BLECharacteristic* ${name} = NULL;
        class Ch${name}Callbacks : public BLECharacteristicCallbacks {
          void onWrite(BLECharacteristic* ${name}) {
            std::string value = ${name}->getValue();
            ${onWrite}
          }
        };
        `


        return `
        ${name} = ${service}->createCharacteristic(
          "${UUID}",
          BLECharacteristic::PROPERTY_READ   |
          BLECharacteristic::PROPERTY_WRITE  |
          BLECharacteristic::PROPERTY_NOTIFY |
          BLECharacteristic::PROPERTY_INDICATE
        );
        ${name}->setCallbacks(new Ch${name}Callbacks());
        ${name}->addDescriptor(new BLE2902());
        `

    }

    b['ble_characteristic_value'] = function (block, generator) {
        var code = "strtol(value.c_str(),NULL,16)";
        return [code, Order.ATOMIC];
    }

    b['ble_characteristic_value_str'] = function (block, generator) {
        var code = "value";
        return [code, Order.ATOMIC];
    }

    b['ble_characteristic_write'] = function (block, generator) {
        const v = block.getInput('INPUT')

        if (v && v.fieldRow?.length) {
            const caracteristica = v.fieldRow[2].value_

            if (caracteristica) {
                const bloque = Blockly.getMainWorkspace()?.getBlockById(caracteristica)
                const nombre = bloque?.nombre
                const valor = generator.valueToCode(block, 'VALUE', Order.ATOMIC)
                return `
${nombre}->setValue(String(${valor}).c_str());
${nombre}->notify();`
            }
        }
        return ''
    }

    b['ble_on_conectado'] = function () {
        return ''
    }
}