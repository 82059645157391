import { Colores,Order, Placas } from "../../Constantes";


const init = {

    helpUrl: 'http://www.arduino.cc/en/Serial/Print',
    init: function () {
        this.setColour(Colores.entradas_salidas)
        this.appendValueInput("BADURATE", 'Number').appendField("Inicializar Serial")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);

    }

}

const print = {
    helpUrl: 'http://www.arduino.cc/en/Serial/Print',
    init: function () {
        this.setColour(Colores.entradas_salidas);
        this.appendValueInput("CONTENT", 'String')
            .appendField("Serial Print");

        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Prints data to the console/serial port as human-readable ASCII text.');
    }
};

const timeout = {
    helpUrl: 'http://www.arduino.cc/en/Serial/Print',
    init: function () {
        this.setColour(Colores.entradas_salidas);
        this.appendValueInput("TIMEOUT", "Number")
            .setCheck("Number").appendField("Serial tiempo de espera")
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
    }
}

const read = {
    helpUrl: 'http://www.arduino.cc/en/Serial/Print',
    init: function () {
        this.setColour(Colores.entradas_salidas);
        this.appendDummyInput().appendField("Datos del puerto serial")
        this.setOutput(true, 'String');

    }
}

const disponible = {
    init: function () {
        this.setColour(Colores.entradas_salidas);
        this.appendDummyInput().appendField("Hay datos en el puerto serial")
        this.setOutput(true, 'Boolean');
        this.setInputsInline(true);
    }
}


const _init = function (block, generator) {
    const placa = Placas[window.placa_definida]
    const content = generator.valueToCode(block, 'BADURATE', Order.ATOMIC) || placa.serial;
    generator.setups_['_setup_serial'] = 'Serial.begin(' + content + ');\n'
    return '';
}

const _print = function (block, generator) {
    const content = generator.valueToCode(block, 'CONTENT', Order.ATOMIC) || '0';
    //const placa = Placas[window.placa_definida]
    //generator.setups_['_setup_serial']='Serial.begin('+placa.serial+');\n'
    var code = 'Serial.println(' + content + ');\n';
    return code;
}

const _read = function (block, generator) {
    const placa = Placas[window.placa_definida]
    //generator.setups_['_setup_serial']='Serial.begin('+placa.serial+');\n'
    var code = "Serial.readStringUntil(\'\\n\')";
    return [code, Order.ATOMIC];
}

const _timeout = function (block, generator) {
    const TIMEOUT = generator.valueToCode(block, 'TIMEOUT', Order.ATOMIC) || '10';
    generator.setups_['_setup_serial_timeout'] = 'Serial.setTimeout(' + TIMEOUT + ');\n'
    return '';
}

const _disponible = function (block, generator) {
    var code = 'Serial.available()';
    return [code, Order.ATOMIC];
}



export default (_a,_b)=>{
    _a['serial_init'] = init
    _a['serial_print'] = print
    _a['serial_timeout'] = timeout
    _a['serial_read'] = read
    _a['serial_disponible'] = disponible
    
    _b['serial_init'] = _init
    _b['serial_print'] = _print
    _b['serial_timeout'] = _timeout
    _b['serial_read'] = _read
    _b['serial_disponible'] = _disponible

}