import { Colores, Order, Placas } from "../../Constantes";

const a = {
    helpUrl: 'http://arduino.cc/en/Reference/AnalogRead',
    init: function () {

        const placa = Placas[window.placa_definida]

        this.setColour(Colores.entradas_salidas);
        this.appendDummyInput()
            .appendField("AnalogRead PIN#")
            .appendField(new Blockly.FieldDropdown(placa.analog), "PIN");
        this.setOutput(true, 'Number');
        this.setTooltip('Return value between 0 and 1024');
    }
};

const a2 = {
    helpUrl: 'http://arduino.cc/en/Reference/AnalogWrite',
    init: function () {

        const placa = Placas[window.placa_definida]

        this.setColour(Colores.entradas_salidas);
        this.appendDummyInput()
            .appendField("AnalogWrite PIN#")
            .appendField(new Blockly.FieldDropdown(placa.digital), "PIN");

        this.appendValueInput("NUM", "Number").setCheck("Number")

        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Write analog value between 0 and 255 to a specific Port');
    }
}

const b = function (block, generator) {
    var dropdown_pin = block.getFieldValue('PIN');
    var code = 'analogRead(' + dropdown_pin + ')';
    return [code, Order.ATOMIC];
}

const b2 = function (block, generator) {
    const dropdown_pin = block.getFieldValue('PIN');
   
    const value_num = generator.valueToCode(block, 'NUM', Order.ATOMIC) || 0;
    const code = 'analogWrite(' + dropdown_pin + ', ' + value_num + ');\n';
    return code
}

function registrar(_a, _b) {
    _a['inout_analog_read'] = a
    _b['inout_analog_read'] = b

    _a['inout_analog_write'] = a2
    _b['inout_analog_write'] = b2
}

export default registrar