import { Colores, Order } from "../../Constantes";

const procedures_defreturn = function (block, generator) {

    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));

    let xfix1 = '';
    if (generator.STATEMENT_PREFIX) {
        xfix1 += generator.injectId(
            generator.STATEMENT_PREFIX, block);
    }
    if (generator.STATEMENT_SUFFIX) {
        xfix1 += generator.injectId(
            generator.STATEMENT_SUFFIX, block);
    }
    if (xfix1) {
        xfix1 = generator.prefixLines(xfix1, generator.INDENT);
    }
    let loopTrap = '';
    if (generator.INFINITE_LOOP_TRAP) {
        loopTrap = generator.prefixLines(
            generator.injectId(
                generator.INFINITE_LOOP_TRAP, block),
            generator.INDENT);
    }
    const branch = generator.statementToCode(block, 'STACK');

    let returnValue =
        generator.valueToCode(block, 'RETURN', funcName == 'setup' ? Order.FUNCTION_CALL : Order.NONE) || '';
    let xfix2 = '';
    if (branch && returnValue) {
        // After executing the function body, revisit this block for the return.
        xfix2 = xfix1;
    }
    if (returnValue) {
        returnValue = generator.INDENT + 'return ' + returnValue + ';\n';
    }
    const args = [];
    const variables = block.getVars();

    const returnType = returnValue ? 'int' : 'void';

    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.getVariableName(variables[i]);
    }


    let code = returnType + ' ' + funcName + '(' + args.join(', ') + ') {\n ' + xfix1 +
        loopTrap + branch + xfix2 + returnValue + '}\n';

    code = generator.scrub_(block, code);
    // Add % so as not to collide with helper functions in definitions list.
    generator.definitions_['%' + funcName] = code;
    return null;
}

const callreturn = function (block, generator) {
    // Call a procedure with a return value.
    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
    const args = [];
    const variables = block.getVars();
    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.valueToCode(block, 'ARG' + i, Order.NONE) ||
            'null';
    }
    const code = funcName + '(' + args.join(', ') + ');\n';
    return [code, Order.UNARY_POSTFIX];
};

const procedures_callnoreturn = function (block, generator) {
    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
    const args = [];
    const variables = block.getVars();
    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.valueToCode(block, 'ARG' + i, Order.NONE) ||
            'null';
    }
    const code = funcName + '(' + args.join(', ') + ');\n';
    return code;
};

const procedures_ifreturn = function (block, generator) {
    // Conditionally return value from a procedure.
    const condition =
        generator.valueToCode(block, 'CONDITION', Order.NONE) ||
        'false';
    let code = 'if (' + condition + ') {\n';
    if (generator.STATEMENT_SUFFIX) {
        // Inject any statement suffix here since the regular one at the end
        // will not get executed if the return is triggered.
        code += generator.prefixLines(
            generator.injectId(
                generator.STATEMENT_SUFFIX, block),
            generator.INDENT);
    }
    if (block.hasReturnValue_) {
        const value =
            generator.valueToCode(block, 'VALUE', Order.NONE) || 'null';
        code += generator.INDENT + 'return ' + value + ';\n';
    } else {
        code += generator.INDENT + 'return;\n';
    }
    code += '}\n';
    return code;
};

function registrar(_a, _b) {
    _b['procedures_defreturn'] = procedures_defreturn
    _b['procedures_defnoreturn'] = procedures_defreturn
    _b['procedures_callreturn'] = callreturn
    _b['procedures_callnoreturn'] = procedures_callnoreturn
    _b['procedures_ifreturn'] = procedures_ifreturn
}

export default registrar