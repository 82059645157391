const a = {

    helpUrl: 'https://www.arduino.cc/reference/en/language/structure/sketch/loop/',
    init: function () {

        this.setDeletable(false)
        this.setEditable(false)
        this.setMovable(false)

        this.jsonInit({
            'message0': 'Por siempre %1',
            'args0': [
                {
                    'type': 'input_statement',
                    'name': 'loop'
                }
            ],
            'deletable': false,
            'startHats': true,
            'colour': '#FFBF00'
        })
    }
}

const b = function (block, generator) {
    let branch = generator.statementToCode(block, 'loop');
    return 'void loop()\n{\n' + branch + '\n}';
}

function registrar(_a, _b) {
    _a['loop'] = a
    _b['loop'] = b
}


export default registrar