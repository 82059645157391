import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
        this.setColour(Colores.operador);
        this.setOutput(true, 'Number');
        this.appendValueInput('A').setCheck("Number")

        this.appendDummyInput().appendField('+')

        this.appendValueInput('B').setCheck("Number")

        this.setInputsInline(true);
    }
}
const b = function (block, generator) {
    const operador = [' + ', Order.ADDITION]
    const order = operador[1]
    const argument0 = generator.valueToCode(block, 'A', order) || '0';
    const argument1 = generator.valueToCode(block, 'B', order) || '0';
    let code;
    code = argument0 + operador[0] + argument1;
    return [code, order];
}

export default (_a, _b) => {
    _a['sumar'] = a
    _b['sumar'] = b
}