import { Order } from "../../Constantes";

const declarar = () => {
    var argument0 = Blockly.Arduino.valueToCode(this, 'VALUE',
        Order.ASSIGNMENT) || '0';
    var varName = Blockly.Arduino.variableDB_.getName(this.getFieldValue('VAR'),
        Blockly.Variables.NAME_TYPE);
    Blockly.Arduino.setups_['setup_var' + varName] = varName + ' = ' + argument0 + ';\n';
    return '';
}

const get = function (block, generator) {
    // Variable getter.
    var code = generator.getVariableName(this.getFieldValue('VAR'));
    return [code, Order.ATOMIC];
};

const set = function (block, generator) {
    // Variable setter.
    var argument0 = Blockly.Arduino.valueToCode(this, 'VALUE', Order.ASSIGNMENT) || '0';
    var varName = generator.getVariableName(this.getFieldValue('VAR'));
    return varName + ' = ' + argument0 + ';\n';
};

const set_dinamico = function (block, generator) {
    // Variable setter.
    var argument0 = generator.valueToCode(this, 'VALUE', Order.ASSIGNMENT) || '0';
    var varName = generator.getVariableName(this.getFieldValue('VAR'));
    return varName + ' = ' + argument0 + ';\n';
};

const get_dinamico = function (block, generator) {
    // Variable getter.
    var code = generator.getVariableName(this.getFieldValue('VAR'),
        Blockly.Variables.NAME_TYPE);
    return [code, Order.ATOMIC];
};

function registrador(_, registrador) {
    registrador['variables_declare'] = declarar
    registrador['variables_get'] = get
    registrador['variables_set'] = set
    registrador['variables_set_dynamic'] = set_dinamico
    registrador['variables_get_dynamic'] = get_dinamico
}



export default registrador