import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
        this.jsonInit({
            'type': 'controls_whileUntil',
            'message0': '%1 %2',
            'args0': [
                {
                    'type': 'field_dropdown',
                    'name': 'MODE',
                    'options': [
                        ['%{BKY_CONTROLS_WHILEUNTIL_OPERATOR_WHILE}', 'WHILE'],
                        ['%{BKY_CONTROLS_WHILEUNTIL_OPERATOR_UNTIL}', 'UNTIL'],
                    ],
                },
                {
                    'type': 'input_value',
                    'name': 'BOOL',
                    'check': 'Boolean',
                },
            ],
            'message1': '%{BKY_CONTROLS_REPEAT_INPUT_DO} %1',
            'args1': [
                {
                    'type': 'input_statement',
                    'name': 'DO',
                },
            ],
            'previousStatement': null,
            'nextStatement': null,
            colour: Colores.control,
            'helpUrl': '%{BKY_CONTROLS_WHILEUNTIL_HELPURL}',
            'extensions': ['controls_whileUntil_tooltip'],
        })
    }
}
const b = function (block, generator) {
    // Do while/until loop.
    const until = block.getFieldValue('MODE') === 'UNTIL';
    let argument0 =
        generator.valueToCode(
            block, 'BOOL',
            until ? Order.LOGICAL_NOT : Order.NONE) ||
        'false';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block);
    if (until) {
        argument0 = '!' + argument0;
    }
    return 'while (' + argument0 + ') {\n' + branch + '}\n';
}

export default (_a, _b) => {
    _a['controls_whileUntil'] = a
    _b['controls_whileUntil'] = b
}