import { Colores, Order } from "../../Constantes";

const a={
    init: function () {
        this.appendDummyInput()
            .appendField("OTA actualizador");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(Colores.wifi);
        this.setTooltip('');
        this.setHelpUrl('');


    }
}

const b=(block,generator)=>{

    let cert=`-----BEGIN CERTIFICATE-----
    MIIDITCCAgmgAwIBAgIUflI6o1+j0WAlrmI/QHdW2lylMg8wDQYJKoZIhvcNAQEL
    BQAwIDEeMBwGA1UEAwwVZXNwMzItd2VidXBkYXRlLmxvY2FsMB4XDTIzMTIyMjE5
    MTMzNloXDTI0MTIyMTE5MTMzNlowIDEeMBwGA1UEAwwVZXNwMzItd2VidXBkYXRl
    LmxvY2FsMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi82RipRc9cGH
    agLr6vUQ3c/zRAA35A3U64biC264femxh9f8HCTDxUtMpQCajvgTYZ8xJVMhpCyz
    GdmgI2aGHQYlKF8AdjhtEUswOm7e7L+D3J3qHs8+aHKKa830OrFI5cKPk3L/ehuG
    7VHTBW8B+xbSPPKthmkkGIZNXOFmKiEnR8Bc6xLaokIp5abZWVcs1xNpWrFyMiEf
    4mP3PlgiO4eMNEHwKdUVOybE2Dv05Ahh2ku62eIDAi9aTuEU/FaJQt+2u/S//Mx0
    QbmJIW60ys7dxWaxRp3HFRivY4scXn/TeHJHYPsJzuj90QUEedzV4Avq8B+vvuC6
    6HeQJ7PKDwIDAQABo1MwUTAdBgNVHQ4EFgQUjzaOFxJFqSm1+s5rbLpNIlMgz6Ew
    HwYDVR0jBBgwFoAUjzaOFxJFqSm1+s5rbLpNIlMgz6EwDwYDVR0TAQH/BAUwAwEB
    /zANBgkqhkiG9w0BAQsFAAOCAQEAUCY0LjoWpqAzfcAVnp8AeUB19pZ/X0bP+fcT
    /seM25uDSaQ+TnzNtPoVOFDe01HbL9+jH0gsJ/+t96kXhLI5bQWafa1onWxXfpAu
    EMSUdNHIh8InEma40CvB+cbrGsutuOduKU24Ld5LZdWkGausgg4iDRUbG54Ba4QW
    AVAZ5LuKcslAj9fFKHGjdxOYplntYbGe/VXou0AxIHheEUCKcdcW2wtZhyMSloPp
    qTfqUHTPkuQW2F4+BoYcQrsw0hmS4t+F10Atm9QczjTOsjNy1T26SiKM0I95iYta
    6/iWFdx9L/1BvfsnDLrRLaV5nRXv+FIm9aNMpQu7aYhWsCGL3A==
    -----END CERTIFICATE-----
    `.split("\n").map((m)=>{
        m=m.replaceAll('    ','')
        if(m==""){
            return '""'
        }
        m='"'+m+'\\n" \\'
        return m
    })

// cert=cert.map((e,i)=>{
//     if(i>0 && i<cert.length-1){
//         return '"'+e.replaceAll("\n","\\n")+'" \\'
//     }
//     if(i==0){
//         return "\\"
//     }
//     return '""'
// })
   
    generator.definitions_['ota_include'] = `
    #include <HttpsOTAUpdate.h>
    static HttpsOTAStatus_t otastatus;
    const char* server_certificate  = ${cert.join("\n")};

void HttpEvent(HttpEvent_t *event){
    switch(event->event_id) {
        case HTTP_EVENT_ERROR:
            Serial.println("Http Event Error");
            break;
        case HTTP_EVENT_ON_CONNECTED:
            Serial.println("Http Event On Connected");
            break;
        case HTTP_EVENT_HEADER_SENT:
            Serial.println("Http Event Header Sent");
            break;
        case HTTP_EVENT_ON_HEADER:
            Serial.printf("Http Event On Header, key=%s, value=%s", event->header_key, event->header_value);
            break;
        case HTTP_EVENT_ON_DATA:
            break;
        case HTTP_EVENT_ON_FINISH:
            Serial.println("Http Event On Finish");
            break;
        case HTTP_EVENT_DISCONNECTED:
            Serial.println("Http Event Disconnected");
            break;
    }
};
    `;

    const api = sessionStorage.getItem("botflow-api-conector")
    const project=sessionStorage.getItem("botflow-project")
    const url = `${api}binario/${project}/app`
    const code=`
HttpsOTA.onHttpEvent(HttpEvent);
HttpsOTA.begin("${url}",server_certificate,false);
`

    return code
}

export default (_a,_b)=>{
    _a['ota']=a
    _b['ota'] = b
}