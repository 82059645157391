import { Colores, Order } from "../../Constantes";



const a = {
    /**
     * Block for advanced math operators with single operand.
     * @this Blockly.Block
     */
    init: function () {
        var OPERATORS =
            [[Blockly.Msg.MATH_SINGLE_OP_ROOT, 'ROOT'],
            [Blockly.Msg.MATH_SINGLE_OP_ABSOLUTE, 'ABS'],
            ['-', 'NEG'],
            ['ln', 'LN'],
            ['log10', 'LOG10'],
            ['e^', 'EXP'],
            ['10^', 'POW10']];
        //this.setHelpUrl(Blockly.Msg.MATH_SINGLE_HELPURL);
        this.setColour(Colores.operador);
        this.setOutput(true, 'Number');
        this.appendValueInput('NUM')
            .setCheck('Number')
            .appendField(new Blockly.FieldDropdown(OPERATORS), 'OP');
        // Assign 'this' to a variable for use in the tooltip closure below.
        var thisBlock = this;
        this.setTooltip(function () {
            var mode = thisBlock.getFieldValue('OP');
            var TOOLTIPS = {
                'ROOT': Blockly.Msg.MATH_SINGLE_TOOLTIP_ROOT,
                'ABS': Blockly.Msg.MATH_SINGLE_TOOLTIP_ABS,
                'NEG': Blockly.Msg.MATH_SINGLE_TOOLTIP_NEG,
                'LN': Blockly.Msg.MATH_SINGLE_TOOLTIP_LN,
                'LOG10': Blockly.Msg.MATH_SINGLE_TOOLTIP_LOG10,
                'EXP': Blockly.Msg.MATH_SINGLE_TOOLTIP_EXP,
                'POW10': Blockly.Msg.MATH_SINGLE_TOOLTIP_POW10
            };
            return TOOLTIPS[mode];
        });
    }
};

const b = function (block, generator) {
    const operator = block.getFieldValue('OP');
    let code;
    let arg;
    if (operator === 'NEG') {
        // Negation is a special case given its different operator precedence.
        arg = generator.valueToCode(block, 'NUM',
            Order.UNARY_NEGATION) || '0';
        if (arg[0] === '-') {
            // --3 is not legal in JS.
            arg = ' ' + arg;
        }
        code = '-' + arg;
        return [code, Order.UNARY_NEGATION];
    }
    if (operator === 'SIN' || operator === 'COS' || operator === 'TAN') {
        arg = generator.valueToCode(block, 'NUM',
            Order.DIVISION) || '0';
    } else {
        arg = generator.valueToCode(block, 'NUM',
            Order.NONE) || '0';
    }
    // First, handle cases which generate values that don't need parentheses
    // wrapping the code.
    switch (operator) {
        case 'ABS':
            code = 'Math.abs(' + arg + ')';
            break;
        case 'ROOT':
            code = 'Math.sqrt(' + arg + ')';
            break;
        case 'LN':
            code = 'Math.log(' + arg + ')';
            break;
        case 'EXP':
            code = 'Math.exp(' + arg + ')';
            break;
        case 'POW10':
            code = 'Math.pow(10,' + arg + ')';
            break;
        case 'ROUND':
            code = 'Math.round(' + arg + ')';
            break;
        case 'ROUNDUP':
            code = 'int(' + arg + ')+1';
            break;
        case 'ROUNDDOWN':
            code = 'int(' + arg + ')-1';
            break;
        case 'SIN':
            code = 'Math.sin(' + arg + ' * M_PI / 180)';
            break;
        case 'COS':
            code = 'Math.cos(' + arg + ' * M_PI / 180)';
            break;
        case 'TAN':
            code = 'Math.tan(' + arg + ' * M_PI / 180)';
            break;
    }
    if (code) {
        return [code, Order.FUNCTION_CALL];
    }
    // Second, handle cases which generate values that may need parentheses
    // wrapping the code.
    switch (operator) {
        case 'LOG10':
            code = 'Math.log10(' + arg + ')';
            break;
        case 'ASIN':
            code = 'Math.asin(' + arg + ') / Math.PI * 180';
            break;
        case 'ACOS':
            code = 'Math.acos(' + arg + ') / Math.PI * 180';
            break;
        case 'ATAN':
            code = 'Math.atan(' + arg + ') / Math.PI * 180';
            break;
        default:
            throw Error('Unknown math operator: ' + operator);
    }
    return [code, Order.DIVISION];
}

function registrar(_a, _b) {
    _a['math_single'] = a
    _b['math_single'] = b
}

export default registrar