export const Placas = {
    arduino: {
        description: "Arduino standard-compatible board",
        digital: [
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
            ["4", "4"],
            ["5", "5"],
            ["6", "6"],
            ["7", "7"],
            ["8", "8"],
            ["9", "9"],
            ["10", "10"],
            ["11", "11"],
            ["12", "12"],
            ["13", "13"],
            ["A0", "A0"],
            ["A1", "A1"],
            ["A2", "A2"],
            ["A3", "A3"],
            ["A4", "A4"],
            ["A5", "A5"]
        ],
        analog: [
            ["A0", "A0"],
            ["A1", "A1"],
            ["A2", "A2"],
            ["A3", "A3"],
            ["A4", "A4"],
            ["A5", "A5"]
        ],
        serial: 115200
    },
    arduino_mega: {
        description: "Arduino Mega-compatible board"
    }
}

export const Order = {
    ATOMIC: 0,            // 0 "" ...
    NEW: 1.1,             // new
    MEMBER: 1.2,          // . []
    FUNCTION_CALL: 2,
    UNARY_PREFIX: 2,     // ()
    INCREMENT: 3,         // ++
    DECREMENT: 3,         // --
    BITWISE_NOT: 4.1,     // ~
    UNARY_PLUS: 4.2,      // +
    UNARY_NEGATION: 4.3,  // -
    LOGICAL_NOT: 4.4,     // !
    TYPEOF: 4.5,          // typeof
    VOID: 4.6,            // void
    DELETE: 4.7,          // delete
    AWAIT: 4.8,           // await
    EXPONENTIATION: 5.0,  // **
    MULTIPLICATION: 5.1,  // *
    DIVISION: 5.2,        // /
    MODULUS: 5.3,         // %
    SUBTRACTION: 6.1,     // -
    ADDITION: 6.2,        // +
    BITWISE_SHIFT: 7,     // << >> >>>
    RELATIONAL: 8,        // < <= > >=
    IN: 8,                // in
    INSTANCEOF: 8,        // instanceof
    EQUALITY: 9,          // == != === !==
    BITWISE_AND: 10,      // &
    BITWISE_XOR: 11,      // ^
    BITWISE_OR: 12,       // |
    LOGICAL_AND: 13,      // &&
    LOGICAL_OR: 14,       // ||
    CONDITIONAL: 15,      // ?:
    ASSIGNMENT: 16,       //: += -= **= *= /= %= <<= >>= ...
    YIELD: 17,            // yield
    COMMA: 18,            // ,
    NONE: 99,             // (...)
};

export const palabras_reservadas="setup,loop,if,else,for,switch,case,while,do,break,continue,return,goto,define,include,HIGH,LOW,INPUT,OUTPUT,INPUT_PULLUP,true,false,interger, constants,floating,point,void,bookean,char,unsigned,byte,int,word,long,float,double,string,String,array,static, volatile,const,sizeof,pinMode,digitalWrite,digitalRead,analogReference,analogRead,analogWrite,tone,noTone,shiftOut,shitIn,pulseIn,millis,micros,delay,delayMicroseconds,min,max,abs,constrain,map,pow,sqrt,sin,cos,tan,randomSeed,random,lowByte,highByte,bitRead,bitWrite,bitSet,bitClear,bit,attachInterrupt,detachInterrupt,interrupts,noInterrupts"

export const Colores = {
    matematica: 230,
    entradas_salidas: '#5CB1D6',
    control: '#FFAB19',
    operador: '#59C059',
    ble:'#0c3b8d',
    wifi:'#5499C7',
    wifi2:'#5FABDE'
}