import { Colores, Order } from "../../Constantes";

const a={
    init: function () {
      this.setColour(Colores.entradas_salidas);
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), 'BOOL')
      this.setOutput(true, 'Boolean');
      this.setTooltip('');
    }
  }
  const b=function(block,generator){
    var code = (block.getFieldValue('BOOL') == 'HIGH') ? 'HIGH' : 'LOW';
    return [code, Order.ATOMIC];
  }

  export default function(_a,_b){
    _a['inout_highlow']=a
    _b['inout_highlow']=b
  }