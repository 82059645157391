import * as bloques from "./Bloques/*/*.js"
import { Order, palabras_reservadas } from "./Constantes"

Blockly.Msg['CONTROLS_REPEAT_HELPURL'] = 'https://www.arduino.cc/reference/es/language/structure/control-structure/for/'
Blockly.Msg['ADD_COMMENT'] = 'Añadir comentario'
Blockly.Msg['DELETE_VARIABLE'] = "Borrar variable"
Blockly.Msg['CANNOT_DELETE_VARIABLE_PROCEDURE'] = "No se puede borrar la variable '%1' porque es parte de la definición de la función '%2'"
Blockly.Msg['COLLAPSED_WARNINGS_WARNING'] = 'Los bloques colapsados contienen advertencias'
Blockly.Msg['COLLAPSE_ALL'] = 'Colapsar bloques'
Blockly.Msg['COLLAPSE_BLOCK'] = 'Colapsar'
Blockly.Msg['DISABLE_BLOCK'] = 'Desactivar'
Blockly.Msg['DELETE_BLOCK'] = 'Borrar'
Blockly.Msg['ENABLE_BLOCK'] = 'Activar'
Blockly.Msg['EXPAND_BLOCK'] = 'Expandir'
Blockly.Msg['EXPAND_ALL'] = 'Expandir todo'
Blockly.Msg['HELP'] = 'Ayuda'
Blockly.Msg['DUPLICATE_BLOCK'] = 'Duplicar'
Blockly.Msg['DUPLICATE_COMMENT'] = 'Duplicar comentario'
Blockly.Msg['NEW_NUMBER_VARIABLE'] = "Crear  variable númerica"
Blockly.Msg['NEW_STRING_VARIABLE'] = "Crear variable de texto"
Blockly.Msg['NEW_VARIABLE'] = "Crear variable"
Blockly.Msg['NEW_VARIABLE_TITLE'] = "Renombrar variable:"
Blockly.Msg['NEW_VARIABLE_TYPE_TITLE'] = "Cambiar el tipo de variable:"
Blockly.Msg['CONTROLS_FOREACH_INPUT_DO'] = 'Hacer'
Blockly.Msg["CONTROLS_WHILEUNTIL_HELPURL"] = "https://www.arduino.cc/reference/es/language/structure/control-structure/while/"
Blockly.Msg["CONTROLS_WHILEUNTIL_INPUT_DO"] = "hacer"
Blockly.Msg["CONTROLS_WHILEUNTIL_OPERATOR_UNTIL"] = "repetir hasta que"
Blockly.Msg["CONTROLS_WHILEUNTIL_OPERATOR_WHILE"] = "repetir mientras"
Blockly.Msg["CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL"] = "Mientras no se cumpla la condición hara las intrucciones indicadas en el 'hacer'."
Blockly.Msg["CONTROLS_WHILEUNTIL_TOOLTIP_WHILE"] = "Mientras se cumpla la condición hara los intrucciones indicadas en el 'hacer'."
Blockly.Msg['CONTROLS_WHILEUNTIL_OPERATOR_UNTIL'] = "repetir hasta que"
Blockly.Msg['CONTROLS_WHILEUNTIL_OPERATOR_WHILE'] = "repetir mientras"
Blockly.Msg['CHANGE_VALUE_TITLE'] = 'Cambiar valor'
Blockly.Msg['DELETE_X_BLOCKS'] = "Borrar %1 bloques?"
Blockly.Msg['DELETE_ALL_BLOCKS'] = "Borrar todos los bloques?"
Blockly.Msg['DIALOG_CANCEL'] = 'Cancelar'
Blockly.Msg['CONTROLS_IF_MSG_IF'] = 'sí'
Blockly.Msg['CONTROLS_IF_MSG_DO'] = 'hacer'
Blockly.Msg['CONTROLS_IF_MSG_THEN'] = 'entonces'
Blockly.Msg['CONTROLS_IF_MSG_ELSE'] = 'si no'
Blockly.Msg['LOGIC_OPERATION_AND'] = 'AND'
Blockly.Msg['LOGIC_OPERATION_OR'] = 'OR'
Blockly.Msg['LOGIC_NEGATE_TITLE'] = 'sí %1'
Blockly.Msg['LOGIC_NEGATE_TITLE'] = 'sí %1'
Blockly.Msg['CONTROLS_FOR_TITLE'] = 'contar %1 de %2 a %3 añadiendo %4'
Blockly.Msg['CONTROLS_FOR_INPUT_DO'] = 'hacer'
Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'] = 'hacer'
Blockly.Msg['CONTROLS_REPEAT_TITLE'] = "repetir %1 vece(s)"
Blockly.Msg.MATH_RANDOM_INT_TITLE = 'número aleatorio entre %1 y %2'
Blockly.Msg.MATH_SINGLE_OP_ABSOLUTE = 'valor absoluo de'
Blockly.Msg.MATH_SINGLE_OP_ROOT = 'raíz cuadrada de'

Blockly.COLORES = {}

Blockly.Arduino = new Blockly.Generator("Arduino");
Blockly.Arduino.addReservedWords(palabras_reservadas);


const { Names } = Blockly.Names

Blockly.Arduino.init = function (workspace) {

    Blockly.Arduino.definitions_ = Object.create(null);
    Blockly.Arduino.setups_ = Object.create(null);
    Blockly.Arduino.ble_services = []

    if (!this.nameDB_) {
        this.nameDB_ = new Blockly.Names(this.RESERVED_WORDS_);
    } else {
        this.nameDB_.reset();
    }

    this.setups_ = Object.create(null);
    this.nameDB_.setVariableMap(workspace.getVariableMap());
    this.nameDB_.populateVariables(workspace);
    this.nameDB_.populateProcedures(workspace);

    const defvars = [];
    // Add developer variables (not created or named by the user).
    const devVarList = Blockly.Variables.allDeveloperVariables(workspace);
    for (let i = 0; i < devVarList.length; i++) {
        defvars.push(
            this.nameDB_.getName(devVarList[i], Names.DEVELOPER_VARIABLE));
    }

    // Add user variables, but only ones that are being used.
    const variables = Blockly.Variables.allUsedVarModels(workspace);
    const vars_types = {
        'String': 'String',
        'Number': 'int',
        '': 'int'

    }
    for (let i = 0; i < variables.length; i++) {

        defvars.push(
            vars_types[variables[i].type] + ' ' + this.nameDB_.getName(variables[i].getId(), Blockly.Variables.CATEGORY_NAME) + ';\n');
    }

    // Declare all of the variables.
    if (defvars.length) {
        this.definitions_['variables'] = defvars.join('');
    }
    this.isInitialized = true;


}

Blockly.Arduino.finish = function (a) {

    a = "  " + a.replace(/\n/g, "\n  ");
    a = a.replace(/\n\s+$/, "\n");


    var imports = [],
        c = [],
        d;
    for (d in Blockly.Arduino.definitions_) {
        var e = Blockly.Arduino.definitions_[d];

        if (e.match(/^#include/)) {
            imports.push(e)
        } else {
            c.push(e)
        }

    }
    e = [];
    for (d in Blockly.Arduino.setups_) e.push(Blockly.Arduino.setups_[d]);

    const inicio_setup = imports.join("\n") + "\n\n" + c.join("\n") + "\nvoid setup() \n{\n " + e.join("\n") + '\n'

    const fin_setup = "\n}\n\n"
    a = a.replace('#iniciosetup', inicio_setup)
    a = a.replace('#finsetup', fin_setup)
    a = a.replace(/\n*$/, "\n\n\n")
  
    return a

    // const ufo_setup=''
    // return (b.join("\n") + "\n\n" + c.join("\n") + "\nvoid setup() \n{\n "+ufo_setup+" " + e.join("\n  ") + "\n}\n\n").replace(/\n\n+/g, "\n\n").replace(/\n*$/, "\n\n\n") + a
};

Blockly.Arduino.scrubNakedValue = function (a) {
    return a + ";\n"
};

Blockly.Arduino.quote_ = function (a) {
    a = a.replace(/\\/g, "\\\\").replace(/\n/g, "\\\n").replace(/\$/g, "\\$").replace(/'/g, "\\'");
    return '"' + a + '"'
};

Blockly.Arduino.scrub_ = function (a, b) {
    if (null === b) return "";
    var c = "";
    if (!a.outputConnection || !a.outputConnection.targetConnection) {
        var d = a.getCommentText();
        d && (c += Blockly.Arduino.prefixLines(d, "// ") + "\n");
        for (var e = 0; e < a.inputList.length; e++) a.inputList[e].type == Blockly.INPUT_VALUE && (d = a.inputList[e].connection.targetBlock()) && (d = Blockly.Arduino.allNestedComments(d)) && (c += Blockly.Arduino.prefixLines(d, "// "))
    }
    e = a.nextConnection && a.nextConnection.targetBlock();
    e = Blockly.Arduino.blockToCode(e);
    return c +
        b + e
};


Blockly.VerticalFlyout.prototype.getFlyoutScale = function () {
    return .70;
};

window.botflow_theme = Blockly.Theme.defineTheme('botflow', {
    'base': window.Blockly.Themes.Zelos,
    'blockStyles': {

    },
    'categoryStyles': {

    },
    'componentStyles': {
        toolboxBackgroundColour: 'white',
        workspaceBackgroundColour: '',
        flyoutBackgroundColour: '#F8F9F9',
        flyoutOpacity: 1,
        blackBackground: 'white'
    },
    'fontStyle': {

    },
    'startHats': true
});

const iniciarBloqueRaiz = (tipo, i) => {
    const ep = Blockly.getMainWorkspace()

    if (!ep.getBlocksByType(tipo).length) {
        let parentBlock = ep.newBlock(tipo);
        parentBlock.initSvg();
        parentBlock.render();
        parentBlock.moveBy(0, 100 * i)
    }
}

const moverBloquesPadres = (et) => {
    try {
        const bloque_setup = et.getBlocksByType('setup')[0]
        const bloque_loop = et.getBlocksByType('loop')[0]
        const y = bloque_setup.relativeCoords.y + bloque_setup.getBoundingRectangle().bottom - bloque_loop.relativeCoords.y
        bloque_loop.moveBy(0, y)

    } catch (err) {
        console.log(err)
    }
}

const crearBloquesPadres = () => {
    iniciarBloqueRaiz('setup', 1)
    iniciarBloqueRaiz('loop', 2)
    Blockly.getMainWorkspace().scrollCenter()
}

const onCodigo = (code, data) => {
    document.dispatchEvent(new CustomEvent("codigo_nuevo", {
        detail: {
            code, data
        }
    }));
}

document.addEventListener('blocky_workspace_changed', function (workspaceChangedEvent) {
    var espacio_trabajo = Blockly.getMainWorkspace()
    espacio_trabajo.addChangeListener(Blockly.Events.disableOrphans)
    espacio_trabajo.addChangeListener(function (event) {
       console.log(event.type)
        if (![Blockly.Events.BLOCK_DRAG, Blockly.Events.BLOCK_MOVE, Blockly.Events.BLOCK_CREATE, Blockly.Events.BLOCK_DELETE, Blockly.Events.BLOCK_CHANGE].includes(event.type)) {
            return
        }
        moverBloquesPadres(espacio_trabajo)
        bloques['Ble'].index.onCreateBle(espacio_trabajo, event)
        bloques['Ble'].index.onChangeBle(espacio_trabajo, event)
        bloques['Ble'].index.onBorrarBle(espacio_trabajo, event)
        onCodigo(
            Blockly.Arduino.workspaceToCode(),
            Blockly.serialization.workspaces.save(window.Blockly.getMainWorkspace())
        )
    })
    crearBloquesPadres()
})


let placa_definida = 'arduino';

window.placa_definida = placa_definida
window.Order = Order
Object.keys(bloques).forEach((b) => {
    console.log(bloques[b].index.default)
    bloques[b].index.default(Blockly.Blocks,Blockly.Arduino)
})
