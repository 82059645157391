import { Colores, Order, Placas } from "../../Constantes";

const a = {
    helpUrl: 'http://arduino.cc/en/Reference/DigitalWrite',
    init: function () {


        const placa = Placas[window.placa_definida]

        this.setColour(Colores.entradas_salidas);
        this.appendDummyInput()
            .appendField("DigitalRead PIN#")
            .appendField(new Blockly.FieldDropdown(placa.digital), "PIN");
        this.setOutput(true, 'Boolean');
        this.setTooltip('');
    }
};

const a2 = {
    helpUrl: 'http://arduino.cc/en/Reference/DigitalWrite',
    init: function () {

        const placa = Placas[window.placa_definida]

        this.setColour(Colores.entradas_salidas);
        this.appendDummyInput()
            .appendField("DigitalWrite PIN#")
            .appendField(new Blockly.FieldDropdown(placa.digital), "PIN")
            .appendField("Stat")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Write digital value to a specific Port');
    }
};

const b = function (block, generator) {
    var dropdown_pin = block.getFieldValue('PIN');
    generator.setups_['setup_input_' + dropdown_pin] = 'pinMode(' + dropdown_pin + ', INPUT);';
    var code = 'digitalRead(' + dropdown_pin + ')'
    return [code, Order.ATOMIC];
}

const b2 = function (block, generator) {
    var dropdown_pin = block.getFieldValue('PIN');
    var dropdown_stat = block.getFieldValue('STAT');
    generator.setups_['setup_output_' + dropdown_pin] = 'pinMode(' + dropdown_pin + ', OUTPUT);';
    var code = 'digitalWrite(' + dropdown_pin + ', ' + dropdown_stat + ');\n'
    return code;
}

function registrar(_a, _b) {
    _a['inout_digital_read'] = a
    _b['inout_digital_read'] = b

    _a['inout_digital_write'] = a2
    _b['inout_digital_write'] = b2
}

export default registrar