import { Colores,Order } from "../../Constantes";

const a = {
    init: function () {
        this.jsonInit({
            'message0': '%1 %2 %3',
            'args0': [
                {
                    'type': 'input_value',
                    'name': 'A',
                },
                {
                    'type': 'field_dropdown',
                    'name': 'OP',
                    'options': [
                        ['=', 'EQ'],
                        ['\u2260', 'NEQ'],
                        ['\u200F<', 'LT'],
                        ['\u200F\u2264', 'LTE'],
                        ['\u200F>', 'GT'],
                        ['\u200F\u2265', 'GTE'],
                    ],
                },
                {
                    'type': 'input_value',
                    'name': 'B',
                }],
            'inputsInline': true,
            'output': 'Boolean',
            'colour': Colores.control,
            'helpUrl': '%{BKY_LOGIC_COMPARE_HELPURL}',
            'extensions': ['logic_compare', 'logic_op_tooltip'],
        })
    }
}

const b = function (block, generator) {
    // Comparison operator.
    const OPERATORS =
        { 'EQ': '==', 'NEQ': '!=', 'LT': '<', 'LTE': '<=', 'GT': '>', 'GTE': '>=' };
    const operator = OPERATORS[block.getFieldValue('OP')];
    const order = (operator === '==' || operator === '!=') ?
        Order.EQUALITY :
        Order.RELATIONAL;
    const argument0 = generator.valueToCode(block, 'A', order) || '0';
    const argument1 = generator.valueToCode(block, 'B', order) || '0';
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
};

function registrar(_a,_b){
    _a['logic_compare']=a
    _b['logic_compare']=b
}

export default registrar