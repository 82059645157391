import { Colores, Order } from "../../Constantes";

const a = {
    init: function () {
      this.setColour(Colores.operador);
      this.setOutput(true, 'Boolean');
      this.appendValueInput('A')
      this.appendDummyInput().appendField('=')
      this.appendValueInput('B')
      this.setInputsInline(true);
    }
  }

const b = function (block, generator) {
    const order = Order.LOGICAL_OR
    const operator = '=='
    let argument0 = generator.valueToCode(block, 'A', order);
    let argument1 = generator.valueToCode(block, 'B', order);
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
}

function registrar(_a, _b) {
    _a['igual_que'] = a
    _b['igual_que'] = b
}

export default registrar