import { Colores, Order } from "../../Constantes";

const a = {
  init: function () {
    this.jsonInit({
      'message0': '%{BKY_LOGIC_NEGATE_TITLE}',
      'args0': [
        {
          'type': 'input_value',
          'name': 'BOOL',
          'check': 'Boolean',
        },
      ],
      'output': 'Boolean',
      'colour': Colores.control,
      'tooltip': '%{BKY_LOGIC_NEGATE_TOOLTIP}',
      'helpUrl': '%{BKY_LOGIC_NEGATE_HELPURL}',
    })
  }
  }

const b = function(block, generator) {
  // Negation.
  const order = Order.LOGICAL_NOT;
  const argument0 =
      generator.valueToCode(block, 'BOOL', order) || 'true';
  const code = '!' + argument0;
  return [code, order];
};

function registrar(_a, _b) {
    _a['logic_negate'] = a
    _b['logic_negate'] = b
}

export default registrar